/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 375px){
    .form-member-group-md{
        width: 95vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .payment-tag{
        display: none;
    }

    .ant-table{
        font-size: 12px !important;
    }

    .text-ellipsis {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
    }

    .antd-custom-message{
        margin-left: 0;
    }

    .landing-logo{
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .logo {
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    /*membership plan drop-down*/
    .plan-dropdown{
        width: 95vw;
    }

    .text-align-center{
        text-align: center;
    }

    .d-wrap{
        column-gap: 6vw;
    }

    .form-member-group{
        width: 95vw;
        margin-top: 1vh;
        font-size: 13px !important;
    }

    .form-member-group-sm{
        width: 30vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-md{
        min-width: 58vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-lg{
        width: 95vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .table-extend{
        width: 95vw;
    }

    .btn-wrap{
        width: 30vw;
    }

    .coupon-btn{
        margin-top: 1.7vh;
    }

    .mid {
        width: 100%;
    }

    .form-member-group-mdm{
        width: 95vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-smxl{
        min-width: 95vw;
        margin-right: 0.5vw;
        margin-top: 2vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group-sml{
        min-width: 95vw;
        margin-right: 0.5vw;
        margin-top: 2vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group-mdx{
        width: 95vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-checkbox{
        width: 90vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .member-name-align{
        text-align: center;
    }

    .adjust-checkbox-top {
        margin-top: 0.2rem !important;
    }

    .payment-tag{
        display: none;
    }

    .ant-table{
        font-size: 12px !important;
    }

    .text-ellipsis {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
    }

    .antd-custom-message{
        margin-left: 0;
    }

    .landing-logo{
        width: 100%;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    /*membership plan drop-down*/
    .plan-dropdown{
        width: 50vw;
    }

    .form-member-group{
        width: 95vw;
        margin-top: 1.5vh;
    }

    .form-member-group-md{
        width: 28.5vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
    }

    .form-member-group-sm{
        min-width: 12vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .table-extend{
        width: 95vw;
    }

    .btn-wrap{
        width: 40vw;
    }

    .coupon-btn{
        margin-top: 1.7vh;
    }

    .mid {
        width: 70%;
    }


    .form-member-group-mdm{
        width: 22vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-smxl{
        width: 6vw;
        margin-right: 0.5vw;
        margin-top: 2vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group-sml{
        width: 10vw;
        margin-right: 0.5vw;
        margin-top: 2vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group-mdx{
        width: 30vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-checkbox{
        width: 50vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .member-name-align{
        text-align: right;
    }

    .adjust-checkbox-top {
        margin-top: 3vh !important;
    }

    .text-ellipsis {
        font-weight: bold;
        font-size: 14px;
    }

    .antd-custom-message{
        margin-left: 0;
    }

    .landing-logo{
        width: 100%;
    }


}