
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    /*membership plan drop-down*/
    .plan-dropdown{
        width: 30vw;
    }

    .flex-cond {
        display: flex;
    }

    .d-wrap{
        column-gap: 4.6vw;
    }
    .d-wrap-sm{
        column-gap: 2vw;
    }
    .d-wrap-lg{
        column-gap: 6vw;
    }

    .btn-float{
        float: right;
    }


    .form-member-group-sm{
        min-width: 12vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-sml{
        min-width: 12vw;
        margin-right: 0.5vw;
        margin-top: 4.5vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group-smxl{
        min-width: 11vw;
        margin-right: 0.5vw;
        margin-top: 4.5vh;
        text-align: center;
        font-size: 13px !important;
    }

    .form-member-group{
        width: 30vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-md{
        width: 31vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-mdx{
        width: 31vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-mdm{
        width: 20vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-xl{
        width: 70vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-xxl{
        min-width: 60vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        margin-left: 2vw;
        font-size: 16px !important;
    }

    .form-member-group-wxl{
        width: 25vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        margin-left: 2vw;
        font-size: 13px !important;
    }

    .form-member-group-lg{
        width: 95vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-checkbox{
        width: 30vw;
        margin-right: 0.5vw;
        margin-top: 3.5vh;
        font-size: 13px !important;
    }

    .coupon-btn{
        margin-top: 2.3vh;
    }
    .mid {
        width: 50%;
    }

    .member-name-align{
        text-align: right;
    }

    .adjust-checkbox-top {
        margin-top: 1vh !important;
    }

    .antd-custom-message{
        margin-left: 20%
    }

    .landing-logo{
        width: 40%;
    }

    .ex-payment-card{
        height: 13vh;
    }
}

/*!* Extra large devices (large laptops and desktops, 1200px and up) *!*/
/*@media only screen and (min-width: 1200px) {*/
/*    .example {background: pink;}*/
/*}*/