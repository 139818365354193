
/* Box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  font-family: filson-pro-regular, sans-serif;
}

span,
b{
  font-family: filson-pro-regular, sans-serif;
}

u{
  color: #005ead;
  cursor: pointer;
}

 /*set up the body */
body {
  color: #2c2c2c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

  line-height: 1.5;
  min-height: 100vh;

  display: grid;
  grid-template-rows: min-content 1fr;

  overflow-x: hidden;
  font-family: filson-pro-regular, sans-serif;
}

/* make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
  font: inherit;
}

/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

input{
  outline: none !important;
  font-size: 12px !important;
}
input:active,
input:focus {
  border-color: #DBDBDB !important;
  box-shadow: none !important;
}

button:active,
button:focus {
  box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.mt-8{
  margin-top: 7rem;
}

.required:before {
  content:" *";
}

.error{
  color: #e9473f;
  font-size: 12px;
}

.error-highlight{
  background-color: #FFF2CC !important;
}

.errorInput,
.errorInput:focus
{
  border: 1px solid #e9473f !important;
  border-radius: 3px;
}

ol{
  margin-top: 1vh !important;
}
ol.roman {list-style-type: lower-roman;}

li{
  margin-bottom: 1vh !important;
}

.font10px{
  font-size: 10.68px;
}
.font12px{
  font-size: 12px !important;
}
.font13px{
  font-size: 13px !important;
}
.font14px{
  font-size: 14px !important;
}
.font15px{
  font-size: 15px !important;
}
.font16px{
  font-size: 16px !important;
}
.font17px{
  font-size: 17px !important;
}
.font18px{
  font-size: 18px !important;
}
.font19px{
  font-size: 19px !important;
}
.font20px{
  font-size: 20px !important;
}

.center {
  margin: auto;
  width: 60%;
}

.mt-3_5 {
  margin-top: 1.14rem;
}

.ml-3{
  margin-left: 4vw;
}
.ml-2{
  margin-left: 2vw;
}

.plan-text{
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.disableSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.extra-space {
  margin-top: 3% !important;
  margin-bottom: 3% !important;
}

/*custom font import*/

@font-face {
  font-family: "filson-pro-regular";
  src: local("filson-pro-regular"),
  url("./assets/fonts/FilsonProRegular.otf") format("OpenType");
}

@font-face {
  font-family: "filson-pro-black";
  src: local("filson-pro-black"),
  url("./assets/fonts/FilsonProBlack.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-bold";
  src: local("filson-pro-bold"),
  url("./assets/fonts/FilsonProBold.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-book";
  src: local("filson-pro-book"),
  url("./assets/fonts/FilsonProBook.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-heavy";
  src: local("filson-pro-heavy"),
  url("./assets/fonts/FilsonProHeavy.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-light";
  src: local("filson-pro-light"),
  url("./assets/fonts/FilsonProLight.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-medium";
  src: local("filson-pro-medium"),
  url("./assets/fonts/FilsonProMedium.otf") format("OpenType");
  font-weight: normal;
}

@font-face {
  font-family: "filson-pro-thin";
  src: local("filson-pro-thin"),
  url("./assets/fonts/FilsonProThin.otf") format("OpenType");
  font-weight: normal;
}

.filson-pro-regular{
  font-family: "filson-pro-regular", sans-serif;
}

.filson-pro-black{
  font-family: "filson-pro-black", sans-serif;
}

.filson-pro-bold{
  font-family: "filson-pro-bold", sans-serif;
}

.filson-pro-book{
  font-family: "filson-pro-book", sans-serif;
}

.filson-pro-heavy{
  font-family: "filson-pro-heavy", sans-serif;
}

.filson-pro-medium{
  font-family: "filson-pro-medium", sans-serif;
}

.filson-pro-light{
  font-family: "filson-pro-light", sans-serif;
}

.filson-pro-thin{
  font-family: "filson-pro-thin", sans-serif;
}

/*loader*/

.spinner {
  margin: 100px auto;
  width: 50%;
  height: 100px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #19b0ca;
  height: 100%;
  width: 10px;
  display: inline-block;
  margin-left: 10px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
.center {
  margin: auto;
  padding: 10px;
}

.ex1-input{

  display: block;
  background-color: #FFFFFF;
  /*border-radius: 0;*/
  /*border: none;*/
  padding: 4px 2px;
  /*border-width: 0;*/
  /*border-color: transparent;*/
  color: #333;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  /*transition: .2s;*/
  cursor: text;
  /* font-weight: inherit; */
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
  border: 1px solid #DBDBDB;
  border-radius: 3px;
  height: 2.15rem;
  padding-top: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ex1-input::placeholder{
  color: transparent;
}
.ex1-input:focus::placeholder{
  color: #7b808c;
}

.ex1-input:focus ~ .ex1-label,
.ex1-input.focus ~ .ex1-label,
.ex1-input.val ~ .ex1-label,
.ex1-input.complete ~ .ex1-label,
.ex1-input.invalid ~ .ex1-label{
  font-size: 0.8rem;
  color:#7b808c;
  top: -1rem;
  left: 0;
}

/* Media query for mobile viewport */
@media screen and (max-width: 400px) {
  #paypal-button-container {
    width: 100%;
  }
}

/* Media query for desktop viewport */
@media screen and (min-width: 400px) {
  #paypal-button-container {
    width: 250px;
  }
}

.black {
  color: #000000 !important;
}

.red {
  color: #ff0000;
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.logo-blue {
  color: #00a893 !important;
}

.parent {
  display: flex;
}

.left {
  flex: 1;
}

.parent::after {
  flex: 1;
  content: '';
}
.ant-picker,.ant-picker:hover {
  border-color: #ced4da !important;
  box-shadow: none;
}

.home-icon {
  color: #005ead;
  cursor: pointer;
}

.home-icon:hover {
  color: #00a893;
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.payment-action {
  color: #00a893;
  cursor: pointer;
}

.payment-action:hover {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

/*google address autocomplete on model pop-up*/
.pac-container {
  z-index: 10000 !important;
}

.btn-close:hover {
  background-color: #F0F0F0;
}

.zero-bottom {
  margin-bottom: 0 !important;
}

.ant-picker-disabled{
  background-color: #E9ECEF !important;
}

input[disabled]{
  color: #000000 !important;
}

.btn-spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.z-1000{
  z-index: 1000;
}

.z-900{
  z-index: 900;
}

.ml-auto{
  margin-left: auto;
}

.antd-custom-message{
  margin-left: 20%
}

.portal-ml-snackbar{
  margin-left: 10%;
}

.url-highlight{
  color: #005ead;
  text-decoration: none;
}
.url-highlight:hover{
  text-decoration: underline;
}

.landing-logo{
  width: 40%;
}

.name-on-card{
  width: 100% !important;
  font-size: 14px !important;
}

.bad-address-bg{
  background-color: #fee598 !important;
}

.address-input-list input{
  background-color: #fee598 !important;
}

.address-input-list label{
  color: #e9473f !important;
}

.table-col-p-right{
  padding-right: 10vw;
}