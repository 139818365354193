/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /*membership plan drop-down*/
    .plan-dropdown{
        width: 50vw;
    }

    .flex-cond {
        display: flex;
    }

    .d-wrap{
        column-gap: 4.6vw;
    }

    .form-member-group{
        width: 40vw;
        margin-right: 0.5vw;
        font-size: 13px !important;
    }

    .form-member-group-sm{
        min-width: 12vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-md{
        min-width: 16vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-lg{
        width: 95vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .form-member-group-checkbox{
        width: 50vw;
        margin-right: 0.5vw;
        margin-top: 1.5vh;
        font-size: 13px !important;
    }

    .coupon-btn{
        margin-top: 1.6vh;
    }

    .mid {
        width: 70%;
    }

    .member-name-align{
        text-align: right;
    }

    .adjust-checkbox-top {
        margin-top: 0 !important;
    }

    .antd-custom-message{
        margin-left: 20%
    }

    .landing-logo{
        width: 40%;
    }
}
